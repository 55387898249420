<template>
    <div class="card_them">
        <b-card title="Card Title" no-body>
            <b-card-header header-tag="nav">
            <b-nav card-header tabs>
                <b-nav-item to="/Setting/imageChange" exact exact-active-class="active" >圖片設定</b-nav-item>
                <b-nav-item to="/Setting/Connection" exact exact-active-class="active">誰聯絡我</b-nav-item>
                <b-nav-item to="/Setting/formData" exact exact-active-class="active">表單資料</b-nav-item>
                <b-nav-item to="/Setting/Count" exact exact-active-class="active">網站計數</b-nav-item>
                <b-nav-item to="/Setting/Logout" exact exact-active-class="active">登出</b-nav-item>
            </b-nav>
            </b-card-header>
            <b-card-body class="text-center">
                <router-view></router-view>        
            </b-card-body>
        </b-card>
    </div>
</template>


<style scoped>

.card_them {
    max-width:80%;
    margin: auto;
    padding-top: 60px;
    padding-bottom: 60px; 
}

</style>

<script>
export default {
    created: function () {
    if (sessionStorage.getItem('account') == null || sessionStorage.getItem('token') == null){
      this.$router.push({name: 'Login'});
    }
  }
}
</script>